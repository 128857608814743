"use strict";

import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

var $ = require('jquery');
window.jQuery = $;
var Isotope = require('isotope-layout');
const jqueryBridget = require('jquery-bridget');
require('@fancyapps/fancybox');

$(function(){
    lazyLoad();
    initGridGallery();    
    toggleHeaderBg();
    fixedNavbar();
    navbarMenuToggle();
    blockContextmenuImages();

    $(window).on('scroll', function(){
        lazyLoad();
        fixedNavbar();
    });

    $(document).on('click', '.logo-link', function(e) {
        e.preventDefault();
        scrollToTop();
    })
});

function initGridGallery() {

    jqueryBridget( 'isotope', Isotope, $);

    $('.gallery__wrapper').isotope({
        itemSelector: '.gallery-image',
        masonry: {
            columnWidth: 10
        }
    })
}

function lazyLoad() {
    var preload_pixel = 250;
    if($(window).width() < 768){
        preload_pixel = 100;
    }

    $.each($('.lazy-img'), function(){
        var target_image_pos = $(this).offset().top,
            image_target = $(this).find('img'),
            source_target = $(this).find('source'),
            this_load = $(this);

        if(image_target.is(':hidden')){
            return
        }

        if(!$(this).parent().hasClass('slider-item')){
            if($(window).scrollTop() >= target_image_pos + $(this).height() + preload_pixel){
                return
            }
        }
        if($(window).scrollTop() >= target_image_pos - $(window).height() - preload_pixel){
            image_target.attr('src', image_target.attr('data-src'));
            image_target.removeAttr('data-src');
            $.each(source_target, function(){
                $(this).attr('srcset', $(this).attr('data-srcset'));
                $(this).removeAttr('data-srcset')
            });


            if(!image_target.attr('src')){

                if(this_load.hasClass('ie_object_lazy') && (isIE() || isEdge())){
                    ie_object_fit_lazy(image_target)
                }

                image_target.on('load', function(){
                    this_load.removeClass('lazy-img');
                });
            }
        }
    });
}

function blockContextmenuImages() {
    $(document).on('contextmenu', 'img', function(e) {
        return false;
    })
}

function toggleHeaderBg() {
    setInterval(() => {
        $('.header-bg.first-bg').toggleClass('js-active');
    }, 10000)
}

function navbarMenuToggle() {
    $('.hamburger').on('click', function(){
        $('.navbar__info').addClass('js-active');
        $('html, body').attr('style', 'overflow-y: hidden;')
    });
    $('.cross-sign').on('click', function(){
        $('.navbar__info').removeClass('js-active');
        $('html, body').removeAttr('style')
    });
}

function fixedNavbar() {
    if (window.pageYOffset > ($('.navbar').outerHeight() - 45)) {
        $('.navbar').addClass('fixed');
    } else {
        $('.navbar').removeClass('fixed');
    }
}

function scrollToTop() {
    $("html, body").stop();
    $("html, body").animate({ scrollTop: "0" }, 1000);
}